import styled from 'styled-components';
import { Text, Title } from 'components';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${Title}, ${Text} {
    max-width: ${px2rem(685)};
    text-align: center;
  }

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(220)} ${px2rem(40)};
  }

  @media screen and (max-width: 997px) {
    padding: 105px 20px 150px;
  }
`;

export const StyledTitle = styled(Title)`
  @media screen and (max-width: 997px) {
    font-size: 32px;
  }
`;

export const StyledText = styled(Text)`
  margin: ${px2rem(50)} 0 ${px2rem(80)};
`;
