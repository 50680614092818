import styled, { keyframes } from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import { px2rem } from 'src/util';
import { Title, LoadImage } from 'components';

const desktopHeight = 972;

export const Wrapper = styled.section`
  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(desktopHeight / 1920) * 100}%;
    position: relative;
  }

  @media screen and (max-width: 997px) {
    padding-top: 33px;
  }
`;

export const DiagonalLine = styled(LoadImage)`
  bottom: 0;
  left: 0;
  position: absolute;
  width: ${(577 / 1920) * 100}%;
`;

export const TopImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    bottom: 0;
    height: 100%;
    right: 0;
    position: absolute;
  }

  @media screen and (max-width: 997px) {
    margin-left: auto;
    max-width: 100%;
  }
`;

const Symbol = styled(LoadImage)`
  height: 100%;
  opacity: 0;
  transition: opacity 1s, transform 1s;
  width: ${(333 / 667) * 100}%;
`;

export const LeftSymbol = styled(Symbol)`
  transform: translateX(-20%);
`;

export const RightSymbol = styled(Symbol)`
  transform: translateX(20%);
`;

export const TopSymbols = styled.div`
  display: flex;
  position: absolute;
  right: ${(185 / 1920) * 100}%;
  top: ${(99 / desktopHeight) * 100}%;
  width: ${(667 / 1920) * 100}%;

  &.isVisible {
    ${Symbol} {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const TextWrapper = styled.div`
  align-self: center;
  z-index: 1;

  @media screen and (min-width: 998px) {
    grid-column: 2 / span 14;
    justify-self: flex-start;
    padding-bottom: ${px2rem(110)};
    padding-left: ${px2rem(120)};
  }

  @media screen and (max-width: 997px) {
    padding: 0 20px;
  }
`;

export const SmallText = styled.h3`
  color: #00778f;
  font: 500 ${px2rem(20)} 'Univia Pro', 'Arial', 'sans-serif';

  @media screen and (max-width: 997px) {
    font-size: 14px;
    max-width: 168px;
  }
`;

export const StyledTitle = styled(Title)`
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(80)};
    margin: ${px2rem(25)} 0;
  }

  @media screen and (max-width: 997px) {
    font-size: 48px;
    margin: 16px 0 25px;
  }
`;

const arrowDownEffect = keyframes`
  0% {
    bottom: 45px;
    opacity: 0;
  }

  25% {
    bottom: 15px;
    opacity: 1;
  }

  50% {
    bottom: 15px;
    opacity: 1;
  }

  75% {
    bottom: 15px;
    opacity: 1;
  }

  100% {
    bottom: 0;
    opacity: 0;
  }
`;

export const ScrollButton = styled(ScrollLink)`
  border: 2px solid #c3c3c3;
  border-radius: ${px2rem(30)};
  display: block;
  height: ${px2rem(70)};
  margin: 0 auto;
  position: relative;
  width: ${px2rem(30)};

  img {
    animation: ${arrowDownEffect} 1.5s ease 0.5s infinite;
    bottom: 15px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
`;
