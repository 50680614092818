import React, { PropsWithChildren } from 'react';
import { useViewport } from 'src/contexts';

export function Desktop({ children }: PropsWithChildren<any>) {
  const { width } = useViewport();

  if (width > 998) {
    return children;
  }

  return null;
}

export function Mobile({ children }: PropsWithChildren<any>) {
  const { width } = useViewport();

  if (width <= 998) {
    return children;
  }

  return null;
}
