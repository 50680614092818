import React from 'react';
import { Map } from 'components';

export default function HomeMap() {
  return (
    <section>
      <Map />
    </section>
  );
}
