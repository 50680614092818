import React, { useCallback, useState, useEffect, useRef } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import {
  Wrapper,
  StyledTitle,
  Content,
  AccordionWrapper,
  AccordionHeader,
  AccordionHeaderImageWrapper,
  DisabledAccordionImage,
  AccordionImage,
  AccordionTitle,
  IntituicoesImage,
  ProntuariosImage,
  FarmaciasImage
} from './styles';

export default function Services() {
  const [currentTab, setCurrentTab] = useState('0');
  const collapseRef = useRef<Collapse>(null);

  const handleChange = useCallback(
    index => {
      if (index && index !== currentTab) {
        setCurrentTab(index);
        // if (collapseRef.current) {
        //   ((collapseRef.current as HTMLDivElement)
        //     .item(index)
        //     .querySelector('rc-collapse-header') as HTMLDivElement).blur();
        // }
      }
    },
    [collapseRef, currentTab]
  );

  return (
    <Wrapper>
      <StyledTitle>Serviços e Benefícios</StyledTitle>
      <Content>
        <AccordionWrapper>
          <Collapse
            accordion
            activeKey={currentTab}
            onChange={handleChange}
            ref={collapseRef}
          >
            <Panel
              header={
                <AccordionHeader>
                  <AccordionHeaderImageWrapper>
                    <DisabledAccordionImage
                      alt=""
                      className="desktop"
                      src={require('assets/instituicoes-accordion-disabled.svg')}
                    />
                    <AccordionImage
                      alt=""
                      src={require('assets/instituicoes-accordion.svg')}
                    />
                  </AccordionHeaderImageWrapper>
                  <AccordionTitle>Para Instituições de Saúde</AccordionTitle>
                </AccordionHeader>
              }
              showArrow={false}
            >
              <ul>
                <li>
                  <img src={require('assets/visibilidade.svg')} alt="" />{' '}
                  <p>
                    Visibilidade, controle e segurança do processo prescritivo
                  </p>
                </li>
                <li>
                  <img src={require('assets/conexao-milhares.svg')} alt="" />
                  <p>Conexão com milhares de farmácias</p>
                </li>
                <li>
                  <img src={require('assets/adaptacao.svg')} alt="" />
                  <p>Capacidade de adaptação à sua estrutura de tecnologia</p>
                </li>
                <li>
                  <img src={require('assets/acuracia.svg')} alt="" />
                  <p>Acurácia na sua base de medicamentos</p>
                </li>
              </ul>
              <IntituicoesImage
                alt="Instituições"
                className="mobile"
                src={require('assets/instituicoes-screen.png')}
              />
            </Panel>
            <Panel
              header={
                <AccordionHeader>
                  <AccordionHeaderImageWrapper>
                    <DisabledAccordionImage
                      alt=""
                      className="desktop"
                      src={require('assets/prontuarios-accordion-disabled.svg')}
                    />
                    <AccordionImage
                      alt=""
                      src={require('assets/prontuarios-accordion.svg')}
                    />
                  </AccordionHeaderImageWrapper>
                  <AccordionTitle>
                    Para Prontuários Eletrônicos, Telemedicina e HIS
                  </AccordionTitle>
                </AccordionHeader>
              }
              showArrow={false}
            >
              <ul>
                <li>
                  <img src={require('assets/prevencao.svg')} alt="" />{' '}
                  <p>Prevenção de Erro Médico</p>
                </li>
                <li>
                  <img src={require('assets/conexao.svg')} alt="" />
                  <p>Conexão com Farmácias</p>
                </li>
                <li>
                  <img src={require('assets/adaptamos.svg')} alt="" />
                  <p>Nos adaptamos à você</p>
                </li>
                <li>
                  <img src={require('assets/medicamentos.svg')} alt="" />
                  <p>Base de Medicamentos</p>
                </li>
              </ul>
              <ProntuariosImage
                alt="Prontuários"
                className="mobile"
                src={require('assets/prontuarios-screen.png')}
              />
            </Panel>
            <Panel
              header={
                <AccordionHeader>
                  <AccordionHeaderImageWrapper>
                    <DisabledAccordionImage
                      alt=""
                      className="desktop"
                      src={require('assets/farmacias-accordion-disabled.svg')}
                    />
                    <AccordionImage
                      alt=""
                      src={require('assets/farmacias-accordion.svg')}
                    />
                  </AccordionHeaderImageWrapper>
                  <AccordionTitle>Para Farmácias</AccordionTitle>
                </AccordionHeader>
              }
              showArrow={false}
            >
              <ul>
                <li>
                  <img src={require('assets/integracao.svg')} alt="" />
                  <p>
                    Integração multicanal com o seu cliente por meio da
                    prescrição eletrônica
                  </p>
                </li>
                <li>
                  <img src={require('assets/conexao-redes.svg')} alt="" />
                  <p>
                    Conexão com as maiores redes de hospitais e clínicas do país
                  </p>
                </li>
                <li>
                  <img src={require('assets/experiencia.svg')} alt="" />
                  <p>
                    Potencializamos a experiência do seu cliente com a sua marca
                  </p>
                </li>
                <li>
                  <img src={require('assets/reducao.svg')} alt="" />
                  <p>
                    Redução de erros originados na dificuldade de interpretação
                    das prescrições manuais
                  </p>
                </li>
              </ul>
              <FarmaciasImage
                alt="Farmácias"
                className="mobile"
                src={require('assets/farmacias-screen.png')}
              />
            </Panel>
          </Collapse>
        </AccordionWrapper>
        {currentTab === '0' && (
          <IntituicoesImage
            alt="Instituições"
            className="desktop"
            src={require('assets/instituicoes-screen.png')}
          />
        )}
        {currentTab === '1' && (
          <ProntuariosImage
            alt="Prontuários"
            className="desktop"
            src={require('assets/prontuarios-screen.png')}
          />
        )}
        {currentTab === '2' && (
          <FarmaciasImage
            alt="Farmácias"
            className="desktop"
            src={require('assets/farmacias-screen.png')}
          />
        )}
      </Content>
    </Wrapper>
  );
}
