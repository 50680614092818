import React, { useCallback, useState } from 'react';
import { Wrapper, StyledTitle, StyledText } from './styles';
import { SingleInputForm } from 'components';
import { FormikHelpers } from 'formik';

export default function ContactHome() {
  return (
    <Wrapper>
      <StyledTitle>
        Estaremos sempre ao seu lado, trabalhando incansavelmente para seu
        sucesso.
      </StyledTitle>
      <StyledText>Junte-se à centenas de parceiros da Nexodata.</StyledText>
      <SingleInputForm buttonText="Fale com um especialista" type="home" />
    </Wrapper>
  );
}
