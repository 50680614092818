import styled from 'styled-components';
import { px2rem } from 'src/util';
import { Title, LoadImage } from 'components';
import WhiteChevron from 'assets/white-chevron.svg';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 998px) {
    align-items: center;
    background: linear-gradient(118.98deg, #4b5966 5.16%, #2e3740 69.41%);
    padding: ${px2rem(140)} ${px2rem(40)};
  }

  @media screen and (max-width: 997px) {
    padding-top: 65px;
  }
`;

export const StyledTitle = styled(Title)`
  @media screen and (min-width: 998px) {
    color: #fff;
    margin-bottom: ${px2rem(120)};
    text-align: center;
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 32px;
    padding: 0 20px;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DisabledAccordionImage = styled.img`
  opacity: 0.2;
  transition: opacity 0.3s;
`;

export const AccordionImage = styled.img`
  @media screen and (min-width: 998px) {
    opacity: 0;
    transition: opacity 0.3s;
  }

  @media screen and (max-width: 997px) {
  }
`;

export const AccordionTitle = styled.h3`
  color: #fff;
  font: 700 ${px2rem(24)} / 120% 'Univia Pro', 'Arial', 'sans-serif';

  @media screen and (min-width: 998px) {
    margin-left: ${px2rem(27)};
    opacity: 0.2;
    transition: opacity 0.3s;
  }

  @media screen and (max-width: 997px) {
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    margin-left: 16px;

    &::after {
      content: url(${WhiteChevron});
      display: block;
      line-height: 0;
      margin-left: auto;
      transition: transform 0.3s;
    }
  }
`;

export const AccordionWrapper = styled.div`
  width: 100%;

  .rc-collapse-anim-active {
    transition: height 0.2s ease-out;
  }

  .rc-collapse-content-inactive {
    display: none;
  }

  .rc-collapse-content {
    overflow: hidden;

    ul {
      display: flex;
      flex-direction: column;
    }

    li {
      align-items: center;
      display: flex;
      padding: ${px2rem(10)} 0;

      img {
        width: ${px2rem(43)};
      }
    }

    p {
      color: #fff;
      font: ${px2rem(16)} / 120% 'Univia Pro', 'Arial', 'sans-serif';
      margin-left: ${px2rem(20)};
    }
  }

  @media screen and (min-width: 998px) {
    border-left: 1px solid #fff;
    margin-right: ${px2rem(140)};
    max-width: ${px2rem(545)};

    .rc-collapse-item {
      position: relative;

      &::before {
        background-color: #00e3d4;
        content: '';
        display: block;
        height: 0;
        left: ${px2rem(-6)};
        position: absolute;
        top: 0;
        transition: height 0.3s;
        width: ${px2rem(11)};
      }

      &.rc-collapse-item-active {
        &::before {
          height: 100%;
        }

        ${DisabledAccordionImage} {
          opacity: 0;
        }

        ${AccordionImage} {
          opacity: 1;
        }

        ${AccordionTitle} {
          opacity: 1;
        }
      }
    }

    .rc-collapse-content {
      padding-left: ${px2rem(65)};
    }
  }

  @media screen and (max-width: 997px) {
    .rc-collapse-item {
      background: linear-gradient(131.83deg, #4b5966 5.16%, #2e3740 69.41%);
      &.rc-collapse-item-active {
        ${AccordionTitle}::after {
          transform: rotateZ(90deg);
        }
      }
    }

    .rc-collapse-content {
      padding: 0 ${px2rem(20)};

      ul {
        margin-bottom: 72px;
      }
    }
  }
`;

export const AccordionHeader = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  @media screen and (min-width: 998px) {
    padding: ${px2rem(20)} 0 ${px2rem(20)} ${px2rem(50)};
  }

  @media screen and (max-width: 997px) {
    padding: 35px 20px;
  }
`;

export const AccordionHeaderImageWrapper = styled.div`
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 998px) {
    height: ${px2rem(78)};
    position: relative;
    width: ${px2rem(78)};

    img {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @media screen and (max-width: 997px) {
    height: ${px2rem(52)};
    width: ${px2rem(52)};
  }
`;

export const IntituicoesImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    width: ${px2rem(677)};
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 72px;
    width: 100%;
  }
`;

export const ProntuariosImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    width: ${px2rem(655)};
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 72px;
    width: 100%;
  }
`;

export const FarmaciasImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    width: ${px2rem(655)};
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 72px;
    width: 100%;
  }
`;
