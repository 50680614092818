import styled from 'styled-components';
import { Link } from 'gatsby';
import { LoadImage, Subtitle, Text } from 'components';
import { px2rem } from 'src/util';
import Chevron from 'assets/chevron.svg';

export const Wrapper = styled.section`
  align-items: center;
  background-color: #f1efe5;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 998px) {
    height: ${px2rem(858)};
  }

  @media screen and (max-width: 997px) {
    padding: 123px 20px 115px;
  }
`;

export const StyledImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    margin-right: ${px2rem(140)};
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    width: ${px2rem(460)};

    &.isVisible {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 997px) {
    height: 335px;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }
`;

export const TextWrapper = styled.div`
  @media screen and (min-width: 998px) {
    max-width: ${px2rem(560)};
  }
`;

export const StyledText = styled(Text)`
  @media screen and (min-width: 998px) {
    margin-top: ${px2rem(30)};
    max-width: ${px2rem(430)};
  }

  @media screen and (max-width: 997px) {
    margin: 15px 0 80px;
  }
`;

export const StyledSubtitle = styled(Subtitle)`
  color: #2e3740;
  font-weight: 700;
  line-height: 150%;

  @media screen and (min-width: 998px) {
    margin-top: ${px2rem(90)};
  }

  @media screen and (max-width: 997px) {
    margin-top: 170px;
  }
`;

export const LinksList = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: ${px2rem(30)};
`;

export const ListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: ${px2rem(30)};
  }
`;

export const StyledLink = styled(Link)`
  align-items: center;
  color: #4c5162;
  display: flex;
  font-family: 'Univia Pro', 'Arial', 'sans-serif';
  letter-spacing: -0.045em;
  padding-right: ${px2rem(24)};
  position: relative;
  transition: color 0.3s;

  &::after {
    content: url(${Chevron});
    line-height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(24)};

    &:hover {
      color: #005370;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 997px) {
    font-size: 20px;
  }
`;

export const LinkImageWrapper = styled.div`
  flex-shrink: 0;
  width: ${px2rem(60)};
`;

export const LinkImage = styled.img`
  height: ${px2rem(30)};
`;
