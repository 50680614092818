import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CustomVisibilitySensor, StyledGrid } from 'components';
import {
  Wrapper,
  DiagonalLine,
  TopImage,
  TopSymbols,
  LeftSymbol,
  RightSymbol,
  TextWrapper,
  SmallText,
  StyledTitle,
  ScrollButton
} from './styles';
import { Desktop, Mobile } from 'src/ResponsiveRules';

export default function HomeTop() {
  const [offset, setOffset] = useState(-90);

  useEffect(() => {
    if (window.innerWidth <= 997) {
      setOffset(-81);
    }
  }, []);

  return (
    <CustomVisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <Wrapper>
          <Desktop>
            <DiagonalLine
              alt=""
              className="desktop"
              src={require('assets/diagonal-line.svg')}
            />
            <TopImage
              alt=""
              className="desktop"
              src={require('assets/banner-home-top.jpg')}
            />
            <TopSymbols className={classNames('desktop', { isVisible })}>
              <LeftSymbol
                src={require('assets/left-symbol-top-home.png')}
                alt=""
              />
              <RightSymbol
                src={require('assets/right-symbol-top-home.png')}
                alt=""
              />
            </TopSymbols>
          </Desktop>
          <StyledGrid>
            <TextWrapper>
              <SmallText>
                O futuro da saúde passa pela prescrição eletrônica.
              </SmallText>
              <StyledTitle>
                Prescrição <br /> eletrônica <br /> é Nexodata.
              </StyledTitle>

              <ScrollButton
                className="desktop"
                duration={400}
                offset={offset}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13) {
                    (e.target as HTMLAnchorElement).click();
                  }
                }}
                smooth
                spy
                tabIndex={0}
                title="Ir para conteúdo"
                to="quem-somos"
              >
                <img
                  alt="Ir para conteúdo"
                  src={require('assets/arrow-down.svg')}
                />
              </ScrollButton>
            </TextWrapper>
          </StyledGrid>
          <Mobile>
            <TopImage
              alt=""
              className="mobile"
              src={require('assets/banner-home-top-mobile.jpg')}
            />
          </Mobile>
        </Wrapper>
      )}
    </CustomVisibilitySensor>
  );
}
