import React from 'react';
import classNames from 'classnames';
import {
  Wrapper,
  Content,
  StyledImage,
  TextWrapper,
  StyledText,
  StyledSubtitle,
  LinksList,
  ListItem,
  StyledLink,
  LinkImageWrapper,
  LinkImage
} from './styles';
import { CustomVisibilitySensor, Title } from 'components';

export default function WhatWeDo() {
  return (
    <Wrapper>
      <Content>
        <CustomVisibilitySensor partialVisibility minTopValue={300}>
          {({ isVisible }) => (
            <StyledImage
              alt=""
              className={classNames('desktop', { isVisible })}
              src={require('assets/o-que-fazemos.svg')}
            />
          )}
        </CustomVisibilitySensor>
        <TextWrapper>
          <Title>O que fazemos</Title>
          <StyledText>
            Uma solução all-in-one de prescrição eletrônica de medicamentos,
            para prestadores de saúde, empresas de tecnologia e farmácias.
          </StyledText>
          <CustomVisibilitySensor partialVisibility minTopValue={200}>
            {({ isVisible }) => (
              <StyledImage
                alt=""
                className={classNames('mobile', { isVisible })}
                src={require('assets/o-que-fazemos.svg')}
              />
            )}
          </CustomVisibilitySensor>
          <StyledSubtitle>
            Interessado na prescrição eletrônica?
            <br />
            Temos a melhor solução para você:
          </StyledSubtitle>
          <LinksList>
            <ListItem>
              <StyledLink to="/receitas?from=1">
                <LinkImageWrapper>
                  <LinkImage
                    alt=""
                    src={require('assets/instituicao-icon.svg')}
                  />
                </LinkImageWrapper>
                Sou instituição de saúde
              </StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/receitas">
                <LinkImageWrapper>
                  <LinkImage
                    alt=""
                    src={require('assets/tecnologia-icon.svg')}
                  />
                </LinkImageWrapper>
                Sou empresa de tecnologia em saúde
              </StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/farmacias">
                <LinkImageWrapper>
                  <LinkImage alt="" src={require('assets/farmacia-icon.svg')} />
                </LinkImageWrapper>
                Sou farmácia
              </StyledLink>
            </ListItem>
          </LinksList>
        </TextWrapper>
      </Content>
    </Wrapper>
  );
}
