import React from 'react';
import classNames from 'classnames';
import {
  Wrapper,
  TextWrapper,
  StyledText,
  StyledImage,
  ImagesWrapper,
  BluePartWrapper,
  ImagePartWrapper,
  GreenPartWrapper,
  WhiteText,
  GreenText
} from './styles';
import { CustomVisibilitySensor, StyledGrid, Title } from 'components';
import LoadImage from 'components/LoadImage';

export default function WhoWeAre() {
  return (
    <CustomVisibilitySensor offset={{ bottom: -200, top: -200 }}>
      {({ isVisible }) => (
        <Wrapper id="quem-somos">
          <StyledGrid>
            <TextWrapper>
              <Title>Quem somos</Title>
              <StyledText>
                A Nexodata nasceu para antecipar o futuro no sistema de saúde —
                esse é o nosso maior propósito.
              </StyledText>
            </TextWrapper>
          </StyledGrid>
          <ImagesWrapper className={classNames({ isVisible })}>
            <BluePartWrapper>
              <StyledImage alt="" src={require('assets/blue-quem-somos.svg')} />
              <div>
                <LoadImage alt="" src={require('assets/time-quem-somos.svg')} />
                <WhiteText>
                  <span>Time veterano no mercado de saúde</span>
                </WhiteText>
                <GreenText>
                  <strong>+30 </strong>
                  anos
                </GreenText>
              </div>
            </BluePartWrapper>
            <ImagePartWrapper>
              <StyledImage
                alt=""
                src={require('assets/quem-somos-middle-banner.png')}
              />
              <div>
                <LoadImage
                  src={require('assets/pacientes-quem-somos.svg')}
                  alt=""
                />
                <WhiteText>
                  <span>
                    Milhões <br /> de pacientes ativos
                  </span>
                </WhiteText>
                <GreenText>
                  <strong>+ 1M</strong>
                </GreenText>
              </div>
            </ImagePartWrapper>
            <GreenPartWrapper>
              <StyledImage
                src={require('assets/green-quem-somos.svg')}
                alt=""
              />
              <div>
                <LoadImage
                  src={require('assets/farmacias-quem-somos.svg')}
                  alt=""
                />
                <WhiteText>
                  <span>Milhares de farmácias por todo o país</span>
                </WhiteText>
                <GreenText>
                  <strong>+20 </strong>
                  mil <br /> farmácias
                </GreenText>
              </div>
            </GreenPartWrapper>
          </ImagesWrapper>
        </Wrapper>
      )}
    </CustomVisibilitySensor>
  );
}
