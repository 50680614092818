import styled from 'styled-components';
import { LoadImage, Text } from 'components';
import { px2rem } from 'src/util';

const height = 640;

export const Wrapper = styled.section`
  > div.grid {
    z-index: 1;
  }

  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(height / 1920) * 100}%;
    position: relative;
  }

  @media screen and (max-width: 997px) {
    padding-top: 80px;
  }
`;

export const TextWrapper = styled.div`
  align-self: center;

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 5;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 4;
  }

  @media screen and (min-width: 998px) {
    max-width: ${px2rem(315)};
  }

  @media screen and (max-width: 997px) {
    padding: 0 20px 48px;
  }
`;

export const StyledText = styled(Text)`
  margin-top: ${px2rem(30)};
`;

export const StyledImage = styled(LoadImage)`
  height: 100%;
`;

export const WhiteText = styled.p`
  align-items: center;
  color: #fff;
  display: flex;
  font: 500 ${(20 / 1920) * 100}vw / 115% 'Univia Pro', 'Arial', 'sans-serif';
  letter-spacing: -0.045em;
  overflow: hidden;

  @media screen and (min-width: 998px) {
    border-left: ${px2rem(5)} solid #fff;
    height: ${(72 / 1920) * 100}vw;
    padding-left: ${(12 / 1920) * 100}vw;

    span {
      opacity: 0;
      transform: translateX(-20%);
    }
  }

  @media screen and (min-width: 1921px) {
    font-size: ${px2rem(20)};
    height: ${px2rem(72)};
    padding-left: ${px2rem(12)};
  }

  @media screen and (max-width: 997px) {
    border-left: 3px solid #fff;
    font-size: 16px;
    height: 60px;
    padding-left: 15px;
  }

  @media screen and (max-width: 340px) {
    font-size: 14px;
  }
`;

export const GreenText = styled.p`
  color: #04ffee;
  flex-shrink: 0;
  font: 500 ${(24 / 1920) * 100}vw / 115% 'Univia Pro', 'Arial', 'sans-serif';
  letter-spacing: -0.045em;
  text-align: right;

  @media screen and (min-width: 998px) {
    height: ${(145 / 1920) * 100}vw;
    opacity: 0;
    padding-top: ${(35 / 1920) * 100}vw;
    text-align: right;
    transform: translateX(-20%);

    strong {
      font-size: ${(70 / 1920) * 100}vw;
    }
  }

  @media screen and (min-width: 1921px) {
    font-size: ${px2rem(24)};

    strong {
      font-size: ${px2rem(70)};
    }
  }

  @media screen and (max-width: 997px) {
    font-size: 16px;
    margin-left: 16px;

    strong {
      font-size: 32px;
    }
  }
`;

const ImageWrapper = styled.div`
  div {
    display: flex;
    z-index: 1;
  }

  @media screen and (min-width: 998px) {
    height: 100%;
    position: relative;

    div {
      align-items: flex-start;
      bottom: ${(165 / height) * 100}%;
      flex-direction: column;
      position: absolute;

      img {
        margin-bottom: ${(20 / 1920) * 100}vw;
      }
    }
  }

  @media screen and (min-width: 1921px) {
    font-size: ${px2rem(70)};

    div img {
      margin-bottom: ${px2rem(20)};
    }
  }

  @media screen and (max-width: 997px) {
    display: flex;
    height: 188px;
    justify-content: center;

    div {
      align-items: center;
      padding: 0 20px;

      img {
        margin-right: 14px;
      }
    }
  }
`;

const blueWidth = 751;

export const BluePartWrapper = styled(ImageWrapper)`
  @media screen and (min-width: 998px) {
    width: ${(blueWidth / 1920) * 100}%;

    div {
      right: ${(196 / blueWidth) * 100}%;

      img {
        width: ${(109 / 1920) * 100}vw;
      }
    }

    ${WhiteText} {
      width: ${(185 / 1920) * 100}vw;

      span {
        transition: opacity 0.5s, transform 0.5s;
      }
    }

    ${GreenText} {
      transition: opacity 0.5s 0.1s, transform 0.5s 0.1s;
      width: ${(231 / 1920) * 100}vw;
    }
  }

  @media screen and (min-width: 1921px) {
    div img {
      width: ${px2rem(109)};
    }

    ${WhiteText} {
      width: ${px2rem(185)};
    }

    ${GreenText} {
      width: ${px2rem(231)};
    }
  }

  @media screen and (max-width: 997px) {
    background-color: #082a3b;

    > img {
      display: none;
    }

    div img {
      width: 39px;
    }

    ${WhiteText} {
      max-width: 145px;
    }
  }
`;

const middlePartWidth = 788;

export const ImagePartWrapper = styled(ImageWrapper)`
  @media screen and (min-width: 998px) {
    margin-left: -${(422 / 1920) * 100}%;
    width: ${(middlePartWidth / 1920) * 100}%;
    z-index: 1;

    ${StyledImage} {
      height: calc(100% + ${((19 * 2) / height) * 100}%);
      left: 0;
      position: relative;
      top: -${(19 / height) * 100}%;
    }

    div {
      right: ${(304 / 788) * 100}%;

      img {
        width: ${(114 / 1920) * 100}vw;
      }
    }

    ${WhiteText} {
      width: ${(135 / 1920) * 100}vw;

      span {
        transition: opacity 0.5s 0.1s, transform 0.5s 0.1s;
      }
    }

    ${GreenText} {
      transition: opacity 0.5s 0.2s, transform 0.5s 0.2s;
      width: ${(161 / 1920) * 100}vw;
    }
  }

  @media screen and (min-width: 1921px) {
    div img {
      width: ${px2rem(114)};
    }

    ${WhiteText} {
      width: ${px2rem(135)};
    }

    ${GreenText} {
      width: ${px2rem(161)};
    }
  }

  @media screen and (max-width: 997px) {
    background-color: #1e516e;

    > img {
      display: none;
    }

    div img {
      width: 34px;
    }

    ${WhiteText} {
      max-width: 105px;
    }
  }
`;

const greenWidth = 648;

export const GreenPartWrapper = styled(ImageWrapper)`
  ${GreenText} {
    span {
    }
  }

  @media screen and (min-width: 998px) {
    margin-left: -${(421 / 1920) * 100}%;
    width: ${(greenWidth / 1920) * 100}%;

    div {
      right: ${(160 / 648) * 100}%;

      img {
        width: ${(98 / 1920) * 100}vw;
      }
    }

    ${WhiteText} {
      width: ${(140 / 1920) * 100}vw;

      span {
        transition: opacity 0.5s 0.2s, transform 0.5s 0.2s;
      }
    }

    ${GreenText} {
      transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
      width: ${(175 / 1920) * 100}vw;
    }
  }

  @media screen and (min-width: 1921px) {
    div img {
      width: ${px2rem(98)};
    }

    ${WhiteText} {
      width: ${px2rem(140)};
    }

    ${GreenText} {
      width: ${px2rem(175)};
    }
  }

  @media screen and (max-width: 997px) {
    background-color: #419aaa;

    > img {
      display: none;
    }

    div img {
      width: 45px;
    }

    ${WhiteText} {
      max-width: 120px;
    }
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 998px) {
    height: 100%;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 0;
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
    overflow: hidden;
  }

  &.isVisible {
    ${WhiteText} span, ${GreenText} {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
